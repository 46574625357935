import React, { useEffect, useState, useCallback } from 'react';
import { Element } from 'react-scroll';
import JobseekerSectionTitle from './JobseekerSectionTitle';
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import Dropzone from '../../common/Dropzone';

const JobseekerFormSectionN = () => {

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [file, setFile] = useState([]);
  const [outputData, setOutputData] = useState();
  const [showForm1, setShowForm1] = useState(true);
  const [pdfLink, setPdfLink] = useState();
  const [selectedColor, setSelectedColor] = useState("blue");
  const [selectedStyle, setSelectedStyle] = useState("casual");
  const [styleChanged, setStyleChanged] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [progressLoadingText, setLoadingText] = useState("Sending Resume to Server");
  const [progressLoadingText2, setLoadingText2] = useState("Sending selected style to Server");
  const [limitNote, setLimitNote] = useState();

  let progressText;
  const loadingText = [
    "Sending Resume to Server.",
    "Sending Resume to Server..",
    "Sending Resume to Server...",
    "Server is sending Resume to AI",
    "Server is sending Resume to AI.",
    "Server is sending Resume to AI..",
    "Server is sending Resume to AI...",
    "AI is Analysing the Resume",
    "AI is Analysing the Resume.",
    "AI is Analysing the Resume..",
    "AI is Analysing the Resume...",
    "AI is Enhancing the Resume",
    "AI is Enhancing the Resume.",
    "AI is Enhancing the Resume..",
    "AI is Enhancing the Resume...",
    "AI is Returning Enhanced Resume data to Server",
    "AI is Returning Enhanced Resume data to Server.",
    "AI is Returning Enhanced Resume data to Server..",
    "AI is Returning Enhanced Resume data to Server...",
    "Server is converting returned data into PDF",
    "Server is converting returned data into PDF.",
    "Server is converting returned data into PDF..",
    "Server is converting returned data into PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF..."
  ];
  
  const loadingText2 = [
    "Sending selected style to Server.",
    "Sending selected style to Server..",
    "Sending selected style to Server...",
    "Server is converting PDF with selected styles",
    "Server is converting PDF with selected styles.",
    "Server is converting PDF with selected styles..",
    "Server is converting PDF with selected styles...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF...",
    "Server is sending a link to download PDF",
    "Server is sending a link to download PDF.",
    "Server is sending a link to download PDF..",
    "Server is sending a link to download PDF..."
  ];

  const colorOptions = [
    { value: 'black', label: 'Black', color: "#000000" },
    { value: 'blue', label: 'Blue', color: "#0000FF" },
    { value: 'burgundy', label: 'Burgundy', color: "#800020" },
    { value: 'green', label: 'Green', color: "#008000" },
    { value: 'grey', label: 'Grey', color: "#808080" },
    { value: 'orange', label: 'Orange', color: "#FFA500" },
    { value: 'purple', label: 'Purple', color: "#A020F0" },
    { value: 'red', label: 'Red', color: "#FF0000" }
  ];

  // const styleOptions = [
  //   { value: 'casual', label: 'Casual' },
  //   { value: 'classic', label: 'Classic' },
  //   { value: 'oldstyle', label: 'Old Style' },
  //   { value: 'banking', label: 'Banking' },
  //   { value: 'fancy', label: 'Fancy' }
  // ];

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 24,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 24,
      width: 24
    },
  });

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    await acceptedFiles.map((file) => setFile(file));
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let i = 0; 
    progressText = setInterval(() => {
      if(i === loadingText.length) {
        clearInterval(progressText);
      } else {
        setLoadingText(loadingText[i]);
        i++;
      }
    }, 1000);
    setDisabled(true);
    setShowForm1(false);
    const formData = new FormData();
    formData.append("inputFile", file);
    // setPdfLink("https://employmentservices.blob.core.windows.net/ml-output/resume_2024-08-14-143304.pdf");
    // const outputContainer = document.getElementById("OutputContainer");
    // outputContainer.innerHTML = `<button class="edu-btn btn-secondary disabled" title="Please submit the style changes to apply to the file">Download</button>`;
    // console.log("form 1 formData: ", formData);
    fetch('https://employee-services.aimodels.ca/process/jseeker', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      clearInterval(progressText);
      setDisabled(false);
      // console.log("Success", data);
      if(data.message.toLowerCase().includes("success")) {
        setOutputData(data.json.latex);
        setPdfLink(data.json.pdf);
      } else {
        setLimitNote(data.message);
      }
    })
    .catch(error => {
      setLoading(false);
      clearInterval(progressText);
      setDisabled(false);
      console.error("Error", error);
    });

    // setOutputData({
    /*  "latex": `\\documentclass[11pt,a4paper,sans]{moderncv}
\usepackage{lmodern}
\moderncvstyle{casual}
\moderncvcolor{blue}
\usepackage[utf8]{inputenc}
\usepackage[scale=0.75]{geometry}

\name{Cynthia}{Dwayne}
\title{Software Developer}
\address{Brooklyn, NY}{}
\phone[mobile]{(123)~456~7890}
\email{cynthia@beamjobs.com}
\social[linkedin]{linkedin.com/in/cynthia-dwayne}
\social[github]{github.com/cynthia-dwayne}

\begin{document}
\makecvtitle

\section{Career Objective}
Throughout my 7-year-plus career as a software developer, I have focused on developing scalable and well-documented code. I enjoy working collaboratively but can also run with projects independently. Excited about the prospect of joining a product-driven company like Acme Corp.

\section{Education}
\cventry{August 2008 -- May 2012}{Bachelor of Science in Computer Science}{University of Delaware}{Newark, DE}{}

\section{Skills}
\cvitem{Languages}{Python (Django), SQL (PostgreSQL, MySQL), JavaScript (ES6, React, Redux, Node.js), Typescript, HTML/CSS}
\cvitem{Cloud}{GCP, AWS}
\cvitem{Other}{CI/CD}

\section{Work Experience}
\cventry{January 2017 -- current}{Software Developer}{QuickBooks}{New York, NY}{}
{\begin{itemize}
  \item Worked on the payments team to save time and improve cash flow for over 50,000 through the development of modern, responsive customer experiences
  \item Led the migration from AWS to GCP for the team to reduce cloud costs by \$260,000 per year
  \item Worked closely with the product team to re-configure the processing of invoices, saving customers over 125,000 manual hours of work per month
  \item Mentored 3 junior front-end developers on the team on React, and documented best practices within the organization
\end{itemize}}

\cventry{January 2014 -- December 2016}{Front-End Developer}{AMR}{New York, NY}{}
{\begin{itemize}
  \item Contributed to the in-house UI library to create reusable components that saved 125+ hours of development per month
  \item Created a web app MVP for a store delivery management platform with 200+ business customers to create, manage, and monitor deliveries using React and Redux
  \item Added features to meditation app with 5,000+ monthly users, enabling audio and video uploads using React and Redux
  \item Improved customer conversion rate by 17\% through A/B testing of different components and combinations, representing \$500,000+ in incremental annual revenue
\end{itemize}}

\cventry{June 2012 -- January 2014}{Help Desk Analyst}{Kelly}{New York, NY}{}
{\begin{itemize}
  \item Diagnosed technical issues for 30+ clients per day by phone, email, and chat, solving issues within 15 minutes on average
  \item Successfully reached solutions for 92\% of computer errors, and escalated more complex tickets to higher tiers to assist clients as quickly as possible
  \item Created user accounts for 50+ clients per week, and assisted them with setting up and customizing their accounts
  \item Created and updated documentation as needed concerning network, software, and hardware problems
\end{itemize}}

\end{document}
`,
     })*/
  }

  const handleColorSelect = (selected) => {
    setSelectedStyle("");
    setStyleChanged(true);
    selected ? setSelectedColor(selected.value) : setSelectedColor("black");
  }

  const handleStyleSelect = (option) => {
    setStyleChanged(true);
    setSelectedStyle(option);
  };

  const handleFormSubmit2 = (e) => {
    e.preventDefault();
    setLoading2(true);
    let i = 0; 
    progressText = setInterval(() => {
      if(i === loadingText2.length) {
        clearInterval(progressText);
      } else {
        setLoadingText2(loadingText2[i]);
        i++;
      }
    }, 1000);
    setDisabled(true);
    setStyleChanged(false);
    const outputContainer = document.getElementById("OutputContainer");
    outputContainer.innerHTML = "";
    const raw = JSON.stringify({
      "latex": outputData,
      "style": {
        "color": selectedColor,
        "style": selectedStyle
      }
    })
    // console.log("form 2 formbody: ", raw);
    fetch('https://employee-services.aimodels.ca/process/jseeker/update', {
      method: 'POST',
      body: raw,
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      setLoading2(false);
      setDisabled(false);
      // console.log("Success 2 ", data);
      if(data.json.message) {
        setMessage(data.json.message);
        console.log("There has been some issue: ", data.json.message); 
      } else {
        // setOutputData(data.json.latex);
        setPdfLink(data.json.pdf);
      }
    })
    .catch(error => {
      setLoading2(false);
      setDisabled(false);
      console.error("Error", error);
    });
  }

  useEffect(() => {
    if(styleChanged) {
      const outputContainer = document.getElementById("OutputContainer");
      outputContainer.innerHTML = "";
    }
    // console.log("style changed");
  }, [styleChanged]);

  useEffect(() => {
    // console.log("Message from 2nd api is : ", message);
    if(message) {
      const outputContainer = document.getElementById("OutputContainer");
      outputContainer.innerHTML = `<p className="unsuccessful-update-resume">There was some issue processing your request, please submit again!</p>`;
      // alert("There was some issue processing your request, please submit again!");
    }
  }, [message]);

  useEffect(() => {
    file.size > 0 ? setDisabled(false) : setDisabled(true);
  }, [file]);

  useEffect(() => {
    if(pdfLink) {
      // console.log("output data is: ", pdfLink);
      const link = document.createElement('a');
      link.href = pdfLink;
      link.innerHTML = "Download";
      link.classList.add("edu-btn", "btn-secondary");
      link.setAttribute('download', 'ai_recommended_resume.pdf'); // Replace 'filename.pdf' with the desired file name
      const outputContainer = document.getElementById("OutputContainer");
      outputContainer.innerHTML = "";
      outputContainer.appendChild(link);
    }
  }, [pdfLink]);

  useEffect(() => {
    console.log("ai resume version: 0.9");
    const script = document.createElement('script');
  
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <Element
        name="aiform" 
        className="jobseeker-form-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <JobseekerSectionTitle
                classes = "text-center"
                title = "Transform Your Resume with AI-Powered Precision"
                // slogan= "Improve your resume instantly"
              />
              <p className="text-center mt-2 mb-2">Elevate your career prospects by refining your resume with AI-enhanced wording and professionally formatted, ready for download.<br></br>
              <br></br>
              <strong>Disclaimer</strong>: Data may not be accurate.</p>
            </div>
            <div className="col-12 row client-details-container mt--40">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                {showForm1 && <form onSubmit={handleFormSubmit} className="w-100 d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <label htmlFor="resumeFile">Upload Resume (.pdf, .doc, .docx)</label><br></br>
                    <Dropzone onDrop={onDrop} accept={{"application/msword": [], "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], "application/pdf": []}} />
                  </div>
                  <button type="submit" disabled={disabled} className={`edu-btn btn-medium lh-1 mt-5${disabled ? " disabled" : ""}`}>Submit</button>
                </form>}
                {pdfLink && <>
                <div className="form2-container d-flex flex-row gap-4 justify-content-center">
                  <form className="changes-container d-flex flex-column align-items-center" onSubmit={handleFormSubmit2}>
                    <p className="mb-0 resume-customization">Customize your Resume</p>
                    <div className="color-options mt-3">
                      <label htmlFor="pdfColor">Choose Color</label>
                      <Select
                        className="basic-single pdf-styling"
                        placeholder="Select color..."
                        name="pdfColor"
                        onChange={handleColorSelect}
                        options={colorOptions}
                        styles={colorStyles}
                        defaultValue={colorOptions[1]}
                      />
                    </div>
                    <div className="style-options mt-5 d-flex flex-row gap-5 justify-content-center align-items-center">
                      <label className="d-flex flex-column">Casual
                        <input
                          type="radio"
                          name="styles"
                          value="casual"
                          checked={selectedStyle === 'casual'}
                          onChange={() => handleStyleSelect('casual')}
                        />
                        {/* <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/casual-resume-${selectedColor ? selectedColor : "black"}.png`} width="220" height="310" alt="resume template with selected styles" /> */}
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/casual-resume-black.png`} width="220" height="310" alt="resume template with selected styles" />
                      </label>
                      <label className="d-flex flex-column">Classic
                        <input
                          type="radio"
                          name="styles"
                          value="classic"
                          checked={selectedStyle === 'classic'}
                          onChange={() => handleStyleSelect('classic')}
                        />
                        {/* <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/classic-resume-${selectedColor ? selectedColor : "black"}.png`} width="220" height="310" alt="resume template with selected styles" /> */}
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/classic-resume-black.png`} width="220" height="310" alt="resume template with selected styles" />
                      </label>
                      <label className="d-flex flex-column">Old Style
                        <input
                          type="radio"
                          name="styles"
                          value="oldstyle"
                          checked={selectedStyle === 'oldstyle'}
                          onChange={() => handleStyleSelect('oldstyle')}
                        />
                        {/* <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/oldstyle-resume-${selectedColor ? selectedColor : "black"}.png`} width="220" height="310" alt="resume template with selected styles" /> */}
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/oldstyle-resume-black.png`} width="220" height="310" alt="resume template with selected styles" />
                      </label>
                      <label className="d-flex flex-column">Banking
                        <input
                          type="radio"
                          name="styles"
                          value="banking"
                          checked={selectedStyle === 'banking'}
                          onChange={() => handleStyleSelect('banking')}
                        />
                        {/* <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/banking-resume-${selectedColor ? selectedColor : "black"}.png`} width="220" height="310" alt="resume template with selected styles" /> */}
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/banking-resume-black.png`} width="220" height="310" alt="resume template with selected styles" />
                      </label>
                      {/* <label className="d-flex flex-column">Fancy
                        <input
                          type="radio"
                          name="styles"
                          value="fancy"
                          checked={selectedStyle === 'fancy'}
                          onChange={() => handleStyleSelect('fancy')}
                        />
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/fancy-resume-${selectedColor ? selectedColor : "black"}.png`} width="220" height="310" alt="resume template with selected styles" />
                        <img className="resume-style-select-img" src={`${process.env.PUBLIC_URL}/images/solutions/jobseeker/fancy-resume-black.png`} width="220" height="310" alt="resume template with selected styles" />
                      </label> */}
                    </div>
                    {styleChanged && <button type="submit" disabled={selectedColor && selectedStyle ? false : true} className={`edu-btn btn-medium lh-1 mt-5${selectedColor && selectedStyle ? "" : " disabled"}`}>Change Style</button>}
                  </form>
                </div>
                </>}
                <div id="OutputContainer" className="mt-4"></div>
                {loading && <div className="mt-4 w-100 text-center">{progressLoadingText}<br></br><img src={`${process.env.PUBLIC_URL}/images/loading_transparent.gif`} alt="illustration" /></div>}
                {loading2 && <div className="mt-4 w-100 text-center">{progressLoadingText2}<br></br><img src={`${process.env.PUBLIC_URL}/images/loading_transparent.gif`} alt="illustration" /></div>}
                {limitNote && <p className="mt--80">{limitNote}</p>}
                <div className="feedback-container mt--80">
                  <h4 className="text-center">Feedback</h4>
                  <p>After reviewing your updated resume from our AI Model. Please share your feedback/comment/suggestion. What features you would like to be added in the future?</p>
                  <iframe
                    id="JotFormIFrame-243230506170242"
                    title="Partner With Us"
                    onLoad={window.parent.scrollTo(0,0)}
                    allowtransparency="true"
                    allow="geolocation; microphone; camera; fullscreen"
                    src="https://form.jotform.com/m2mtech/ai-resume-feedback"
                    frameBorder="0"
                    className="ai-resume-form-frame"
                    scrolling="yes"
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default JobseekerFormSectionN;